.consent_text {
  font-size: 12px !important;
  max-width: 500px;
}

.landingPage {
  position: relative;

  .consent {
    display: grid;
    place-items: center;
    width: 100%;
    top: 0;
    background: rgba(51, 51, 51, 0.249);
    height: 100%;
    padding: 32px;
    position: fixed;
    &_container {
      display: grid;
      justify-items: center;
      gap: 16px;
      max-width: 600px;
      background: #fff;
      padding: 32px;
      border-radius: 10px;
    }
  }
}

.hero {
  display: grid;
  gap: 16px;
  background: rgb(228, 246, 252);

  &_left {
    display: grid;
    justify-items: flex-start;
    color: #34314c;
    gap: 24px;

    &_imgContainer {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      gap: 16px;
      img {
        max-width: 250px;
      }
    }
    &_title {
      font-weight: 600;
      font-size: 32px;
      line-height: 1.3;
    }
    &_description {
      font-weight: 300;
      font-size: 19.2px;
      line-height: 1.3;
    }
  }
  &_right {
    &_video {
      width: 100%;
      height: 210px;
      background: #34314c;
    }
  }
}

.middle {
  display: grid;
  gap: 10px;

  &_boxContainer {
    justify-content: center;
  }
  &_boxContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.middleLow {
  display: flex;
  flex-direction: column;
  gap: 20.8px;
  .mdCard {
    display: grid;
    justify-items: center;
    grid-template-rows: 1fr auto;
    gap: 11.2px;
    text-align: center;
  }
}

.footer {
  display: grid;
  gap: 16px;
  background-image: url('https://kizan.cognni.ai/img/footer_backround.jpg');
  &_box {
    background: #fff;
    width: 100%;
    padding: 24px;
    display: grid;
    gap: 1rem;
    place-items: center;
    box-shadow: 0px 4px 15px hsla(0, 0%, 67%, 0.25);
    border-radius: 6px;
  }

  &_ul {
    color: #34314c;
    p {
      font-weight: 600;
      font-size: 32px;
      line-height: 1.3;
    }
  }

  ul {
    margin-top: 16px;
    padding: 0 0 0 1rem;
    display: grid;
    gap: 8px;
  }
}

// Globals
.landingPage {
  p {
    margin: 0;
  }
}
.ld_pd {
  padding: 16px;
}
.ld_pd_full {
  padding: 16px;
}

.b_bottom {
  border-bottom: solid rgba(51, 51, 51, 0.172) 1px;
}

.landing {
  &_button {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 11.2px;
    border-radius: 50px;
    padding: 12.8px 25.6px;
    font-size: 17.6px;
    color: #fff;
    background: rgb(33, 150, 243);
    &:disabled {
      background: gray !important;
      cursor: not-allowed;
    }

    img {
      max-width: 24px;
    }
  }
}

.infoBox {
  color: rgba(64, 64, 64, 0.67);
  font-weight: 300;
  font-size: 14.4px;
  img {
    box-shadow: 0px 4px 15px hsla(0, 0%, 67%, 0.25);
    width: 100%;
  }
}

.landing_input {
  border-radius: 50px;
  border: unset;
  outline: unset;
  padding: 12.8px 25.6px;
  height: 47px;
}

// Mobile view
@media screen and (min-width: 800px) {
  .ld_pd {
    padding: 4rem 0 4rem 4rem;
  }
  .ld_pd_full {
    padding: 4rem;
  }
  .hero {
    grid-template-columns: auto auto;
    align-items: center;
    gap: 5rem;
    &_left {
      &_imgContainer {
        gap: 2rem;
        img:nth-child(1) {
        }
      }
      &_title {
        font-size: 40px;
      }
      &_description {
        font-size: 19.2px;
      }
    }
    &_right {
      &_video {
        height: 410px;
      }
    }
  }
  .middle {
    p {
      max-width: 800px;
    }
    gap: 2rem;
    &_boxContainer {
      justify-content: center;
      gap: 2rem;
    }
    &_boxContainer {
      display: flex;
    }
  }

  // globals
  .infoBox {
    img {
      max-width: 300px;
      width: 100%;
    }
  }
  .middleLow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;

    .mdCard {
      display: grid;
      text-align: center;
      align-content: flex-start;
      gap: 0.7rem;
      max-width: 220px;
      justify-content: flex-start;
      text-align: center;
      img {
        width: auto;
        max-height: 100px;
      }
    }
  }

  .footer {
    grid-template-columns: 500px auto;
    gap: 2rem;

    &_box {
    }
    ul {
    }
  }
}
